import React from 'react'
import SignaturePad from './SignaturePad'

const Step5 = ({ formData, setFormData }) => {
  return (
    <div>
      <h2>Step 5: Firma Digital</h2>
      <SignaturePad setFormData={setFormData} formData={formData} />
    </div>
  )
}

export default Step5
