// import React, { useEffect, useState } from 'react'
// import { Routes, Route } from 'react-router-dom'
// import SplashInicio from './components/SplashInicio/SplashInicio'
// import Formulario from './components/Formulario/Formulario'
// import './App.css'

// const App = () => {
//   const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait')

//   useEffect(() => {
//     const handleResize = () => {
//       setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait')
//     }

//     window.addEventListener('resize', handleResize)
//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [])

//   return (
//     <div className={`container ${orientation}`}>
//       <div className="mobile-app">
//         <Routes>
//           <Route path="/" element={<SplashInicio />} />
//           <Route path="/formulario" element={<Formulario />} />
//         </Routes>
//       </div>
//       <div className="desktop-message">
//         Esta app está optimizada para dispositivos móviles
//       </div>
//     </div>
//   )
// }

// export default App


import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import SplashInicio from './components/SplashInicio/SplashInicio'
import Formulario from './components/Formulario/Formulario'
import MultiStep from './components/Multistep/MultiStep'
import './App.css'

const App = () => {
  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait')

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait')
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={`container ${orientation}`}>
      <div className="mobile-app">
        <Routes>
          <Route path="/" element={<SplashInicio />} />
          <Route path="/formulario" element={<Formulario />} />
          <Route path="/multistep" element={<MultiStep />} />
        </Routes>
      </div>
      <div className="desktop-message">
        Esta app está optimizada para dispositivos móviles
      </div>
    </div>
  )
}

export default App
