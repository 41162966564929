// import React from 'react'
// import { Link } from 'react-router-dom'
// import { FiUser } from "react-icons/fi";

// const SplashInicio = () => {
//   return (
//     <div>
//        <h2>Formulario Unico de Afiliacion</h2>
//        <Link to="/formulario">
//          <button> <FiUser />
//           Comenzar</button>
//        </Link>
//     </div>
//   )
// }

// export default SplashInicio



import React from 'react'
import { Link } from 'react-router-dom'
import { FiUser } from "react-icons/fi"

const SplashInicio = () => {
  return (
    <div>
       <h2>Formulario Unico de Afiliacion</h2>
       <Link to="/multistep">
         <button>
           <FiUser />
           Comenzar
         </button>
       </Link>
    </div>
  )
}

export default SplashInicio
