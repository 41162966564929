// import React from 'react'

// const Step1 = ({ formData, setFormData }) => {
//   return (
//     <><div>
//     <h2>Datos Personales</h2>
//     <label>
//       Apellido:
//       <input
//         type="text"
//         value={formData.apellido}
//         onChange={(e) => setFormData({ ...formData, apellido: e.target.value })}
//       />
//     </label>

    

//   </div>
//   <div>
//     <label>
//         Nombre:
//         <input
//           type="text"
//           value={formData.nombre}
//           onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
//         />
//       </label>
//     </div>
//     </>
    
//   )
// }

// export default Step1

// import React from 'react'

// const Step1 = ({ formData, setFormData }) => {
//   const handleChange = (e) => {
//     const { name, value } = e.target
//     setFormData({ ...formData, [name]: value })
//   }

//   return (
//     <div>
//       <form>
//         <label>
//           Apellido:
//           <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Nombre:
//           <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Sexo:
//           <select name="sexo" value={formData.sexo} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             <option value="Masculino">Masculino</option>
//             <option value="Femenino">Femenino</option>
//             <option value="Otro">Otro</option>
//           </select>
//         </label>
//         <br />
//         <label>
//           Fecha de Nacimiento:
//           <input type="date" name="fechaNacimiento" value={formData.fechaNacimiento} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Nacionalidad:
//           <select name="nacionalidad" value={formData.nacionalidad} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             <option value="Arg">Argentina</option>
//             <option value="Chi">Chile</option>
//             <option value="Bol">Bolivia</option>
//             <option value="Per">Perú</option>
//           </select>
//         </label>
//         <br />
//         <label>
//           DNI:
//           <input type="number" name="dni" value={formData.dni} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           CUIL:
//           <input type="number" name="cuil" value={formData.cuil} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Estado Civil:
//           <select name="estadoCivil" value={formData.estadoCivil} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             <option value="Casado/a">Casado/a</option>
//             <option value="Soltero/a">Soltero/a</option>
//             <option value="Viudo/a">Viudo/a</option>
//             <option value="Divorciado/a">Divorciado/a</option>
//           </select>
//         </label>
//         <br />
//         <label>
//           Dirección:
//           <input type="text" name="direccion" value={formData.direccion} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Provincia:
//           <input type="text" name="provincia" value={formData.provincia} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Departamento / Municipio:
//           <input type="text" name="departamentoMunicipio" value={formData.departamentoMunicipio} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Código Postal:
//           <input type="text" name="codigoPostal" value={formData.codigoPostal} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Teléfono:
//           <input type="number" name="telefono" value={formData.telefono} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           e-mail:
//           <input type="email" name="email" value={formData.email} onChange={handleChange} />
//         </label>
//       </form>
//     </div>
//   )
// }

// export default Step1





// import React, { useEffect } from 'react'

// const Step1 = ({ formData, setFormData, provincias, municipios }) => {
//   const handleChange = (e) => {
//     const { name, value } = e.target
//     console.log(`Changing ${name} to ${value}`)
//     setFormData({ ...formData, [name]: value })
//   }

//   useEffect(() => {
//     if (formData.provincia) {
//       const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia)
//       if (provinciaSeleccionada) {
//         console.log("Selected Provincia ID:", provinciaSeleccionada.id)
//       }
//     }
//   }, [formData.provincia, provincias])

//   return (
//     <div>
//       <h2>Step 1: Información Personal</h2>
//       <form>
//         <label>
//           Apellido:
//           <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Nombre:
//           <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Sexo:
//           <select name="sexo" value={formData.sexo} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             <option value="Masculino">Masculino</option>
//             <option value="Femenino">Femenino</option>
//           </select>
//         </label>
//         <br />
//         <label>
//           Fecha de Nacimiento:
//           <input type="date" name="fechaNacimiento" value={formData.fechaNacimiento} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Nacionalidad:
//           <input type="text" name="nacionalidad" value={formData.nacionalidad} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           DNI:
//           <input type="number" name="dni" value={formData.dni} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           CUIL:
//           <input type="number" name="cuil" value={formData.cuil} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Estado Civil:
//           <select name="estadoCivil" value={formData.estadoCivil} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             <option value="Soltero/a">Soltero/a</option>
//             <option value="Casado/a">Casado/a</option>
//             <option value="Divorciado/a">Divorciado/a</option>
//             <option value="Viudo/a">Viudo/a</option>
//             <option value="Separado/a">Separado/a</option>
//             <option value="Concubino/a">Concubino/a</option>
//           </select>
//         </label>
//         <br />
//         <label>
//           Dirección:
//           <input type="text" name="direccion" value={formData.direccion} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Provincia:
//           <select name="provincia" value={formData.provincia} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             {provincias.map(provincia => (
//               <option key={provincia._id} value={provincia.nombre}>{provincia.nombre}</option>
//             ))}
//           </select>
//         </label>
//         <br />
//         <label>
//           Departamento / Municipio:
//           <select name="departamentoMunicipio" value={formData.departamentoMunicipio} onChange={handleChange}>
//             <option value="">Selecciona...</option>
//             {municipios.map(municipio => (
//               <option key={municipio._id} value={municipio.nombre}>{municipio.nombre}</option>
//             ))}
//           </select>
//         </label>
//         <br />
//         <label>
//           Código Postal:
//           <input type="number" name="codigoPostal" value={formData.codigoPostal} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           Teléfono:
//           <input type="number" name="telefono" value={formData.telefono} onChange={handleChange} />
//         </label>
//         <br />
//         <label>
//           e-mail:
//           <input type="email" name="email" value={formData.email} onChange={handleChange} />
//         </label>
//       </form>
//     </div>
//   )
// }

// export default Step1







import React, { useEffect } from 'react'

const Step1 = ({ formData, setFormData, provincias, municipios }) => {
  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(`Changing ${name} to ${value}`)
    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    if (formData.provincia) {
      const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia)
      if (provinciaSeleccionada) {
        console.log("Selected Provincia ID:", provinciaSeleccionada.id)
      }
    }
  }, [formData.provincia, provincias])

  return (
    <div>
      <h2>Step 1: Información Personal</h2>
      <form>
        <label>
          Apellido:
          <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} />
        </label>
        <br />
        <label>
          Nombre:
          <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} />
        </label>
        <br />
        <label>
          Sexo:
          <select name="sexo" value={formData.sexo} onChange={handleChange}>
            <option value="">Selecciona...</option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </select>
        </label>
        <br />
        <label>
          Fecha de Nacimiento:
          <input type="date" name="fechaNacimiento" value={formData.fechaNacimiento} onChange={handleChange} />
        </label>
        <br />
        <label>
          Nacionalidad:
          <input type="text" name="nacionalidad" value={formData.nacionalidad} onChange={handleChange} />
        </label>
        <br />
        <label>
          DNI:
          <input type="number" name="dni" value={formData.dni} onChange={handleChange} />
        </label>
        <br />
        <label>
          CUIL:
          <input type="number" name="cuil" value={formData.cuil} onChange={handleChange} />
        </label>
        <br />
        <label>
          Estado Civil:
          <select name="estadoCivil" value={formData.estadoCivil} onChange={handleChange}>
            <option value="">Selecciona...</option>
            <option value="Soltero/a">Soltero/a</option>
            <option value="Casado/a">Casado/a</option>
            <option value="Divorciado/a">Divorciado/a</option>
            <option value="Viudo/a">Viudo/a</option>
            <option value="Separado/a">Separado/a</option>
            <option value="Concubino/a">Concubino/a</option>
          </select>
        </label>
        <br />
        <label>
          Dirección:
          <input type="text" name="direccion" value={formData.direccion} onChange={handleChange} />
        </label>
        <br />
        <label>
          Provincia:
          <select name="provincia" value={formData.provincia} onChange={(e) => {
            handleChange(e)
            console.log(`Provincia selected: ${e.target.value}`)
          }}>
            <option value="">Selecciona...</option>
            {provincias.map(provincia => (
              <option key={provincia._id} value={provincia.nombre}>{provincia.nombre}</option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Departamento / Municipio:
          <select name="departamentoMunicipio" value={formData.departamentoMunicipio} onChange={handleChange}>
            <option value="">Selecciona...</option>
            {municipios.map(municipio => (
              <option key={municipio._id} value={municipio.nombre}>{municipio.nombre}</option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Código Postal:
          <input type="number" name="codigoPostal" value={formData.codigoPostal} onChange={handleChange} />
        </label>
        <br />
        <label>
          Teléfono:
          <input type="number" name="telefono" value={formData.telefono} onChange={handleChange} />
        </label>
        <br />
        <label>
          e-mail:
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>
      </form>
    </div>
  )
}

export default Step1
