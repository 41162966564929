
// import React, { useState, useEffect } from 'react'
// import Step1 from './Step1'
// import Step2 from './Step2'
// import Step3 from './Step3'
// import Step4 from './Step4'
// import Step5 from './Step5'
// import StepIndicator from './StepIndicator'
// import './MultiStepForm.css'

// const MultiStepForm = () => {
//   const [currentStep, setCurrentStep] = useState(1)
//   const [provincias, setProvincias] = useState([])
//   const [municipios, setMunicipios] = useState([])
//   const [empresas, setEmpresas] = useState([])
//   const [yacimientos, setYacimientos] = useState([])

//   const [formData, setFormData] = useState({
//     apellido: '',
//     nombre: '',
//     sexo: '',
//     fechaNacimiento: '',
//     nacionalidad: '',
//     dni: '',
//     cuil: '',
//     estadoCivil: '',
//     direccion: '',
//     provincia: '',
//     departamentoMunicipio: '',
//     codigoPostal: '',
//     telefono: '',
//     email: '',
//     // Campos para Step2
//     nombreFamiliar: '',
//     vinculoFamiliar: '',
//     fechaNacimientoFamiliar: '',
//     sexoFamiliar: '',
//     // Campos para Step3
//     empresa: '',
//     yacimiento: '',
//     areaTrabajo: '',
//     legajo: '',
//     categoriaReciboSueldo: '',
//     fechaIngreso: '',
//     profesion: '',
//     obraSocial: '',
//     correoCorporativo: '',
//     nombreJefeDirecto: '',
//     // Campos para Step4
//     nombreApellido: '',
//     dniBancario: '',
//     banco: '',
//     numeroCuenta: '',
//     cbu: '',
//     sucursal: '',
//     // Firma digital
//     firma: ''
//   })

//   useEffect(() => {
//     fetchProvincias()
//     fetchEmpresas()
//   }, [])

//   useEffect(() => {
//     if (formData.provincia) {
//       const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia)
//       if (provinciaSeleccionada) {
//         fetchMunicipios(provinciaSeleccionada.id)
//       }
//     }
//   }, [formData.provincia, provincias])

//   const fetchProvincias = async () => {
//     try {
//       const response = await fetch('http://localhost:4000/api/provincias')
//       const data = await response.json()
//       setProvincias(data)
//       console.log("Provincias:", data)
//     } catch (error) {
//       console.error('Error fetching provincias:', error)
//     }
//   }

//   const fetchMunicipios = async (provinciaId) => {
//     try {
//       const response = await fetch(`http://localhost:4000/api/municipios`)
//       const data = await response.json()
//       const filteredMunicipios = data.filter(municipio => municipio.provinciaId === provinciaId)
//       setMunicipios(filteredMunicipios)
//       console.log("Municipios:", filteredMunicipios)
//     } catch (error) {
//       console.error('Error fetching municipios:', error)
//     }
//   }

//   const fetchEmpresas = async () => {
//     try {
//       const response = await fetch('http://localhost:4000/api/empresas')
//       const data = await response.json()
//       setEmpresas(data)
//       console.log("Empresas:", data)
//     } catch (error) {
//       console.error('Error fetching empresas:', error)
//     }
//   }

//   const fetchYacimientos = async (empresaId) => {
//     try {
//       const response = await fetch(`http://localhost:4000/api/yacimientos`)
//       const data = await response.json()
//       const filteredYacimientos = data.filter(yacimiento => yacimiento.empresas.some(e => e.id === empresaId))
//       setYacimientos(filteredYacimientos)
//       console.log("Yacimientos:", filteredYacimientos)
//     } catch (error) {
//       console.error('Error fetching yacimientos:', error)
//     }
//   }

//   const handleNext = () => {
//     setCurrentStep(currentStep + 1)
//   }

//   const handleBack = () => {
//     setCurrentStep(currentStep - 1)
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()

//     const afiliadoData = {
//       nombre: `${formData.apellido} ${formData.nombre}`,
//       sexo: formData.sexo,
//       fechaNacimiento: formData.fechaNacimiento,
//       dni: formData.dni,
//       cuil: formData.cuil,
//       e_civil: formData.estadoCivil,
//       domicilio: formData.direccion,
//       provincia: formData.provincia,
//       departamentoMunicipio: formData.departamentoMunicipio,
//       codigoPostal: formData.codigoPostal,
//       telefono: formData.telefono,
//       email: formData.email,
//       // Datos laborales
//       empresa: formData.empresa,
//       yacimiento: formData.yacimiento,
//       area_trabajo: formData.areaTrabajo,
//       legajo: formData.legajo,
//       cat_r_sueldo: formData.categoriaReciboSueldo,
//       fecha_ingreso: formData.fechaIngreso,
//       profesion: formData.profesion,
//       obra_social: formData.obraSocial,
//       email_empresa: formData.correoCorporativo,
//       nombre_jefe: formData.nombreJefeDirecto,
//       // Datos bancarios
//       banco: formData.banco,
//       nCuenta: formData.numeroCuenta,
//       cbu: formData.cbu,
//       sucursal: formData.sucursal,
//       // Firma digital
//       firma: formData.firma,
//       // Familiares
//       familiares: [{
//         nombre: formData.nombreFamiliar,
//         vinculo: formData.vinculoFamiliar,
//         fechaNacimiento: formData.fechaNacimientoFamiliar,
//         sexo: formData.sexoFamiliar,
//       }]
//     }

//     try {
//       const response = await fetch('http://localhost:4000/api/afiliados', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(afiliadoData)
//       })
//       const result = await response.json()
//       console.log('Success:', result)
//     } catch (error) {
//       console.error('Error:', error)
//     }
//   }

//   return (
//     <div className="multi-step-form">
//       <div className='header-form'>
//         <StepIndicator currentStep={currentStep} totalSteps={5} />
//         <h2>{`Step ${currentStep}: ${currentStep === 1 ? 'Información Personal' : currentStep === 2 ? 'Información del Familiar' : currentStep === 3 ? 'Información Laboral' : currentStep === 4 ? 'Información Bancaria' : 'Firma Digital'}`}</h2>
//       </div>
//       <div className="form-content">
//         {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} provincias={provincias} municipios={municipios} />}
//         {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
//         {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} empresas={empresas} fetchYacimientos={fetchYacimientos} yacimientos={yacimientos} />}
//         {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}
//         {currentStep === 5 && <Step5 formData={formData} setFormData={setFormData} />}
//       </div>
//       <div className="navigation-buttons">
//         {currentStep > 1 && <button onClick={handleBack}>Volver</button>}
//         {currentStep < 5 ? (
//           <button onClick={handleNext}>Siguiente</button>
//         ) : (
//           <button onClick={handleSubmit} disabled={!formData.firma}>Afiliarse</button>
//         )}
//       </div>
//     </div>
//   )
// }

// export default MultiStepForm


// import React, { useState, useEffect } from 'react'
// import Step1 from './Step1'
// import Step2 from './Step2'
// import Step3 from './Step3'
// import Step4 from './Step4'
// import Step5 from './Step5'
// import StepIndicator from './StepIndicator'
// import './MultiStepForm.css'

// const MultiStepForm = () => {
//   const [currentStep, setCurrentStep] = useState(1)
//   const [provincias, setProvincias] = useState([])
//   const [municipios, setMunicipios] = useState([])
//   const [empresas, setEmpresas] = useState([])
//   const [yacimientos, setYacimientos] = useState([])

//   const [formData, setFormData] = useState({
//     apellido: '',
//     nombre: '',
//     sexo: '',
//     fechaNacimiento: '',
//     nacionalidad: '',
//     dni: '',
//     cuil: '',
//     estadoCivil: '',
//     direccion: '',
//     provincia: '',
//     departamentoMunicipio: '',
//     codigoPostal: '',
//     telefono: '',
//     email: '',
//     // Campos para Step2
//     nombreFamiliar: '',
//     vinculoFamiliar: '',
//     fechaNacimientoFamiliar: '',
//     sexoFamiliar: '',
//     // Campos para Step3
//     empresa: '',
//     yacimiento: '',
//     areaTrabajo: '',
//     legajo: '',
//     categoriaReciboSueldo: '',
//     fechaIngreso: '',
//     profesion: '',
//     obraSocial: '',
//     correoCorporativo: '',
//     nombreJefeDirecto: '',
//     // Campos para Step4
//     nombreApellido: '',
//     dniBancario: '',
//     banco: '',
//     numeroCuenta: '',
//     cbu: '',
//     sucursal: '',
//     // Firma digital
//     firma: ''
//   })

//   useEffect(() => {
//     fetchProvincias()
//     fetchEmpresas()
//   }, [])

//   useEffect(() => {
//     if (formData.provincia) {
//       const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia)
//       if (provinciaSeleccionada) {
//         fetchMunicipios(provinciaSeleccionada.id)
//       }
//     }
//   }, [formData.provincia, provincias])

//   const fetchProvincias = async () => {
//     try {
//       const response = await fetch('http://localhost:4000/api/provincias')
//       const data = await response.json()
//       setProvincias(data)
//       console.log("Provincias:", data)
//     } catch (error) {
//       console.error('Error fetching provincias:', error)
//     }
//   }

//   const fetchMunicipios = async (provinciaId) => {
//     try {
//       const response = await fetch(`http://localhost:4000/api/municipios`)
//       const data = await response.json()
//       const filteredMunicipios = data.filter(municipio => municipio.provinciaId === provinciaId)
//       setMunicipios(filteredMunicipios)
//       console.log("Municipios:", filteredMunicipios)
//     } catch (error) {
//       console.error('Error fetching municipios:', error)
//     }
//   }

//   const fetchEmpresas = async () => {
//     try {
//       const response = await fetch('http://localhost:4000/api/empresas')
//       const data = await response.json()
//       setEmpresas(data)
//       console.log("Empresas:", data)
//     } catch (error) {
//       console.error('Error fetching empresas:', error)
//     }
//   }

//   const fetchYacimientos = async (empresaId) => {
//     try {
//       const response = await fetch(`http://localhost:4000/api/yacimientos`)
//       const data = await response.json()
//       const filteredYacimientos = data.filter(yacimiento => yacimiento.empresas.some(e => e.id === empresaId))
//       setYacimientos(filteredYacimientos)
//       console.log("Yacimientos:", filteredYacimientos)
//     } catch (error) {
//       console.error('Error fetching yacimientos:', error)
//     }
//   }

//   const handleNext = () => {
//     setCurrentStep(currentStep + 1)
//   }

//   const handleBack = () => {
//     setCurrentStep(currentStep - 1)
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()

//     const afiliadoData = {
//       nombre: `${formData.apellido} ${formData.nombre}`,
//       sexo: formData.sexo,
//       fechaNacimiento: formData.fechaNacimiento,
//       dni: formData.dni,
//       cuil: formData.cuil,
//       e_civil: formData.estadoCivil,
//       domicilio: formData.direccion,
//       provincia: formData.provincia,
//       departamentoMunicipio: formData.departamentoMunicipio,
//       codigoPostal: formData.codigoPostal,
//       telefono: formData.telefono,
//       email: formData.email,
//       // Datos laborales
//       empresa: formData.empresa,
//       yacimiento: formData.yacimiento,
//       area_trabajo: formData.areaTrabajo,
//       legajo: formData.legajo,
//       cat_r_sueldo: formData.categoriaReciboSueldo,
//       fecha_ingreso: formData.fechaIngreso,
//       profesion: formData.profesion,
//       obra_social: formData.obraSocial,
//       email_empresa: formData.correoCorporativo,
//       nombre_jefe: formData.nombreJefeDirecto,
//       // Datos bancarios
//       banco: formData.banco,
//       nCuenta: formData.numeroCuenta,
//       cbu: formData.cbu,
//       sucursal: formData.sucursal,
//       // Firma digital
//       firma: formData.firma,
//       // Familiares
//       familiares: [{
//         nombre: formData.nombreFamiliar,
//         vinculo: formData.vinculoFamiliar,
//         fechaNacimiento: formData.fechaNacimientoFamiliar,
//         sexo: formData.sexoFamiliar,
//       }]
//     }

//     try {
//       const response = await fetch('http://localhost:4000/api/afiliados', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(afiliadoData)
//       })
//       const result = await response.json()
//       console.log('Success:', result)
//     } catch (error) {
//       console.error('Error:', error)
//     }
//   }

//   return (
//     <div className="multi-step-form">
//       <div className='header-form'>
//         <StepIndicator currentStep={currentStep} totalSteps={5} />
//         <h2>{`Step ${currentStep}: ${currentStep === 1 ? 'Información Personal' : currentStep === 2 ? 'Información del Familiar' : currentStep === 3 ? 'Información Laboral' : currentStep === 4 ? 'Información Bancaria' : 'Firma Digital'}`}</h2>
//       </div>
//       <div className="form-content">
//         {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} provincias={provincias} municipios={municipios} />}
//         {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
//         {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} empresas={empresas} fetchYacimientos={fetchYacimientos} yacimientos={yacimientos} />}
//         {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}
//         {currentStep === 5 && <Step5 formData={formData} setFormData={setFormData} />}
//       </div>
//       <div className="navigation-buttons">
//         {currentStep > 1 && <button onClick={handleBack}>Volver</button>}
//         {currentStep < 5 ? (
//           <button onClick={handleNext}>Siguiente</button>
//         ) : (
//           <button onClick={handleSubmit} disabled={!formData.firma}>Afiliarse</button>
//         )}
//       </div>
//     </div>
//   )
// }

// export default MultiStepForm










// import React, { useState, useEffect } from 'react';
// import Step1 from './Step1';
// import Step2 from './Step2';
// import Step3 from './Step3';
// import Step4 from './Step4';
// import Step5 from './Step5';
// import StepIndicator from './StepIndicator';
// import './MultiStepForm.css';

// const MultiStepForm = () => {
//   const [currentStep, setCurrentStep] = useState(1);
//   const [provincias, setProvincias] = useState([]);
//   const [municipios, setMunicipios] = useState([]);
//   const [empresas, setEmpresas] = useState([]);
//   const [yacimientos, setYacimientos] = useState([]);

//   const [formData, setFormData] = useState({
//     apellido: '',
//     nombre: '',
//     sexo: '',
//     fechaNacimiento: '',
//     nacionalidad: '',
//     dni: '',
//     cuil: '',
//     estadoCivil: '',
//     direccion: '',
//     provincia: '',
//     departamentoMunicipio: '',
//     codigoPostal: '',
//     telefono: '',
//     email: '',
//     // Campos para Step2
//     nombreFamiliar: '',
//     vinculoFamiliar: '',
//     fechaNacimientoFamiliar: '',
//     sexoFamiliar: '',
//     // Campos para Step3
//     empresa: '',
//     yacimiento: '',
//     areaTrabajo: '',
//     legajo: '',
//     categoriaReciboSueldo: '',
//     fechaIngreso: '',
//     profesion: '',
//     obraSocial: '',
//     correoCorporativo: '',
//     nombreJefeDirecto: '',
//     // Campos para Step4
//     nombreApellido: '',
//     dniBancario: '',
//     banco: '',
//     numeroCuenta: '',
//     cbu: '',
//     sucursal: '',
//     // Firma digital
//     firma: ''
//   });

//   useEffect(() => {
//     fetchProvincias();
//     fetchEmpresas();
//   }, []);

//   useEffect(() => {
//     if (formData.provincia) {
//       const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
//       if (provinciaSeleccionada) {
//         console.log('Selected Provincia ID:', provinciaSeleccionada.id);
//         fetchMunicipios(provinciaSeleccionada.id);
//       }
//     }
//   }, [formData.provincia, provincias]);

//   const fetchProvincias = async () => {
//     try {
//       const response = await fetch('http://192.168.18.123:4000/api/provincias'); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       setProvincias(data);
//       console.log('Provincias:', data);
//     } catch (error) {
//       console.error('Error fetching provincias:', error);
//       alert('Error fetching provincias: ' + error.message);
//     }
//   };

//   const fetchMunicipios = async (provinciaId) => {
//     try {
//       const response = await fetch(`http://192.168.18.123:4000/api/municipios`); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       const filteredMunicipios = data.filter(municipio => municipio.provinciaId === provinciaId);
//       setMunicipios(filteredMunicipios);
//       console.log('Municipios:', filteredMunicipios);
//     } catch (error) {
//       console.error('Error fetching municipios:', error);
//       alert('Error fetching municipios: ' + error.message);
//     }
//   };

//   const fetchEmpresas = async () => {
//     try {
//       const response = await fetch('http://192.168.18.123:4000/api/empresas'); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       setEmpresas(data);
//       console.log('Empresas:', data);
//     } catch (error) {
//       console.error('Error fetching empresas:', error);
//       alert('Error fetching empresas: ' + error.message);
//     }
//   };

//   const fetchYacimientos = async (empresaId) => {
//     try {
//       const response = await fetch(`http://192.168.18.123:4000/api/yacimientos`); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       const filteredYacimientos = data.filter(yacimiento => yacimiento.empresas.some(e => e.id === empresaId));
//       setYacimientos(filteredYacimientos);
//       console.log('Yacimientos:', filteredYacimientos);
//     } catch (error) {
//       console.error('Error fetching yacimientos:', error);
//       alert('Error fetching yacimientos: ' + error.message);
//     }
//   };

//   const handleNext = () => {
//     setCurrentStep(currentStep + 1);
//   };

//   const handleBack = () => {
//     setCurrentStep(currentStep - 1);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const afiliadoData = {
//       nombre: `${formData.apellido} ${formData.nombre}`,
//       sexo: formData.sexo,
//       fechaNacimiento: formData.fechaNacimiento,
//       dni: formData.dni,
//       cuil: formData.cuil,
//       e_civil: formData.estadoCivil,
//       domicilio: formData.direccion,
//       provincia: formData.provincia,
//       departamentoMunicipio: formData.departamentoMunicipio,
//       codigoPostal: formData.codigoPostal,
//       telefono: formData.telefono,
//       email: formData.email,
//       // Datos laborales
//       empresa: formData.empresa,
//       yacimiento: formData.yacimiento,
//       area_trabajo: formData.areaTrabajo,
//       legajo: formData.legajo,
//       cat_r_sueldo: formData.categoriaReciboSueldo,
//       fecha_ingreso: formData.fechaIngreso,
//       profesion: formData.profesion,
//       obra_social: formData.obraSocial,
//       email_empresa: formData.correoCorporativo,
//       nombre_jefe: formData.nombreJefeDirecto,
//       // Datos bancarios
//       banco: formData.banco,
//       nCuenta: formData.numeroCuenta,
//       cbu: formData.cbu,
//       sucursal: formData.sucursal,
//       // Firma digital
//       firma: formData.firma,
//       // Familiares
//       familiares: [{
//         nombre: formData.nombreFamiliar,
//         vinculo: formData.vinculoFamiliar,
//         fechaNacimiento: formData.fechaNacimientoFamiliar,
//         sexo: formData.sexoFamiliar,
//       }]
//     };

//     try {
//       const response = await fetch('http://192.168.18.123:4000/api/afiliados', { // Cambia a la dirección IP de tu servidor
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(afiliadoData)
//       });
//       const result = await response.json();
//       console.log('Success:', result);
//     } catch (error) {
//       console.error('Error:', error);
//       alert('Error submitting form: ' + error.message);
//     }
//   };

//   return (
//     <div className="multi-step-form">
//       <div className='header-form'>
//         <StepIndicator currentStep={currentStep} totalSteps={5} />
//         <h2>{`Step ${currentStep}: ${currentStep === 1 ? 'Información Personal' : currentStep === 2 ? 'Información del Familiar' : currentStep === 3 ? 'Información Laboral' : currentStep === 4 ? 'Información Bancaria' : 'Firma Digital'}`}</h2>
//       </div>
//       <div className="form-content">
//         {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} provincias={provincias} municipios={municipios} />}
//         {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
//         {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} empresas={empresas} fetchYacimientos={fetchYacimientos} yacimientos={yacimientos} />}
//         {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}
//         {currentStep === 5 && <Step5 formData={formData} setFormData={setFormData} />}
//       </div>
//       <div className="navigation-buttons">
//         {currentStep > 1 && <button onClick={handleBack}>Volver</button>}
//         {currentStep < 5 ? (
//           <button onClick={handleNext}>Siguiente</button>
//         ) : (
//           <button onClick={handleSubmit} disabled={!formData.firma}>Afiliarse</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MultiStepForm;












// import React, { useState, useEffect } from 'react';
// import Step1 from './Step1';
// import Step2 from './Step2';
// import Step3 from './Step3';
// import Step4 from './Step4';
// import Step5 from './Step5';
// import StepIndicator from './StepIndicator';
// import './MultiStepForm.css';

// const MultiStepForm = () => {
//   const [currentStep, setCurrentStep] = useState(1);
//   const [provincias, setProvincias] = useState([]);
//   const [municipios, setMunicipios] = useState([]);
//   const [empresas, setEmpresas] = useState([]);
//   const [yacimientos, setYacimientos] = useState([]);

//   const [formData, setFormData] = useState({
//     apellido: '',
//     nombre: '',
//     sexo: '',
//     fechaNacimiento: '',
//     nacionalidad: '',
//     dni: '',
//     cuil: '',
//     estadoCivil: '',
//     direccion: '',
//     provincia: '',
//     departamentoMunicipio: '',
//     codigoPostal: '',
//     telefono: '',
//     email: '',
//     // Campos para Step2
//     nombreFamiliar: '',
//     vinculoFamiliar: '',
//     fechaNacimientoFamiliar: '',
//     sexoFamiliar: '',
//     // Campos para Step3
//     empresa: '',
//     yacimiento: '',
//     areaTrabajo: '',
//     legajo: '',
//     categoriaReciboSueldo: '',
//     fechaIngreso: '',
//     profesion: '',
//     obraSocial: '',
//     correoCorporativo: '',
//     nombreJefeDirecto: '',
//     // Campos para Step4
//     nombreApellido: '',
//     dniBancario: '',
//     banco: '',
//     numeroCuenta: '',
//     cbu: '',
//     sucursal: '',
//     // Firma digital
//     firma: ''
//   });

//   useEffect(() => {
//     fetchProvincias();
//     fetchEmpresas();
//   }, []);

//   useEffect(() => {
//     if (formData.provincia) {
//       const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
//       if (provinciaSeleccionada) {
//         console.log('Selected Provincia ID:', provinciaSeleccionada.id);
//         fetchMunicipios(provinciaSeleccionada.id);
//       }
//     }
//   }, [formData.provincia, provincias]);

//   const fetchProvincias = async () => {
//     try {
//       const response = await fetch('http://asijeminapis.website:2874/api/provincias'); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       setProvincias(data);
//       console.log('Provincias:', data);
//     } catch (error) {
//       console.error('Error fetching provincias:', error);
//       alert('Error fetching provincias: ' + error.message);
//     }
//   };

//   const fetchMunicipios = async (provinciaId) => {
//     try {
//       const response = await fetch(`http://asijeminapis.website:2874/api/municipios`); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       const filteredMunicipios = data.filter(municipio => municipio.provinciaId === provinciaId);
//       setMunicipios(filteredMunicipios);
//       console.log('Municipios:', filteredMunicipios);
//     } catch (error) {
//       console.error('Error fetching municipios:', error);
//       alert('Error fetching municipios: ' + error.message);
//     }
//   };

//   const fetchEmpresas = async () => {
//     try {
//       const response = await fetch('http://asijeminapis.website:2874/api/empresas'); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       setEmpresas(data);
//       console.log('Empresas:', data);
//     } catch (error) {
//       console.error('Error fetching empresas:', error);
//       alert('Error fetching empresas: ' + error.message);
//     }
//   };

//   const fetchYacimientos = async (empresaId) => {
//     try {
//       const response = await fetch(`http://asijeminapis.website:2874/api/yacimientos`); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       const filteredYacimientos = data.filter(yacimiento => yacimiento.empresas.some(e => e.id === empresaId));
//       setYacimientos(filteredYacimientos);
//       console.log('Yacimientos:', filteredYacimientos);
//     } catch (error) {
//       console.error('Error fetching yacimientos:', error);
//       alert('Error fetching yacimientos: ' + error.message);
//     }
//   };

//   const handleNext = () => {
//     setCurrentStep(currentStep + 1);
//   };

//   const handleBack = () => {
//     setCurrentStep(currentStep - 1);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
//     const municipioSeleccionado = municipios.find(mun => mun.nombre === formData.departamentoMunicipio);
//     const empresaSeleccionada = empresas.find(emp => emp.nombre === formData.empresa);
//     const yacimientoSeleccionado = yacimientos.find(yac => yac.nombre === formData.yacimiento);

//     const afiliadoData = {
//       nombre: `${formData.apellido} ${formData.nombre}`,
//       sexo: formData.sexo,
//       fechaNacimiento: formData.fechaNacimiento,
//       dni: formData.dni,
//       cuil: formData.cuil,
//       e_civil: formData.estadoCivil,
//       domicilio: formData.direccion,
//       provincia: provinciaSeleccionada ? provinciaSeleccionada._id : null,
//       localidad: municipioSeleccionado ? municipioSeleccionado._id : null,
//       codigoPostal: formData.codigoPostal,
//       telefono: formData.telefono,
//       email: formData.email,
//       // Datos laborales
//       empresa: empresaSeleccionada ? empresaSeleccionada.idempresa : null,
//       yacimiento: yacimientoSeleccionado ? yacimientoSeleccionado.idyacimiento : null,
//       area_trabajo: formData.areaTrabajo,
//       legajo: formData.legajo,
//       cat_r_sueldo: formData.categoriaReciboSueldo,
//       fecha_ingreso: formData.fechaIngreso,
//       profesion: formData.profesion,
//       obra_social: formData.obraSocial,
//       email_empresa: formData.correoCorporativo,
//       nombre_jefe: formData.nombreJefeDirecto,
//       // Datos bancarios
//       banco: formData.banco,
//       nCuenta: formData.numeroCuenta,
//       cbu: formData.cbu,
//       sucursal: formData.sucursal,
//       // Firma digital
//       firma: formData.firma,
//       // Familiares
//       familiares: [{
//         nombre: formData.nombreFamiliar,
//         vinculo: formData.vinculoFamiliar,
//         fechaNacimiento: formData.fechaNacimientoFamiliar,
//         sexo: formData.sexoFamiliar,
//       }]
//     };

//     try {
//       const response = await fetch('http://asijeminapis.website:2874/api/afiliados', { // Cambia a la dirección IP de tu servidor
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(afiliadoData)
//       });
//       const result = await response.json();
//       console.log('Success:', result);
//     } catch (error) {
//       console.error('Error:', error);
//       alert('Error submitting form: ' + error.message);
//     }
//   };

//   return (
//     <div className="multi-step-form">
//       <div className='header-form'>
//         <StepIndicator currentStep={currentStep} totalSteps={5} />
//         <h2>{`Step ${currentStep}: ${currentStep === 1 ? 'Información Personal' : currentStep === 2 ? 'Información del Familiar' : currentStep === 3 ? 'Información Laboral' : currentStep === 4 ? 'Información Bancaria' : 'Firma Digital'}`}</h2>
//       </div>
//       <div className="form-content">
//         {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} provincias={provincias} municipios={municipios} />}
//         {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
//         {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} empresas={empresas} fetchYacimientos={fetchYacimientos} yacimientos={yacimientos} />}
//         {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}
//         {currentStep === 5 && <Step5 formData={formData} setFormData={setFormData} />}
//       </div>
//       <div className="navigation-buttons">
//         {currentStep > 1 && <button onClick={handleBack}>Volver</button>}
//         {currentStep < 5 ? (
//           <button onClick={handleNext}>Siguiente</button>
//         ) : (
//           <button onClick={handleSubmit} disabled={!formData.firma}>Afiliarse</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MultiStepForm;












// import React, { useState, useEffect } from 'react';
// import Step1 from './Step1';
// import Step2 from './Step2';
// import Step3 from './Step3';
// import Step4 from './Step4';
// import Step5 from './Step5';
// import StepIndicator from './StepIndicator';
// import './MultiStepForm.css';

// const MultiStepForm = () => {
//   const [currentStep, setCurrentStep] = useState(1);
//   const [provincias, setProvincias] = useState([]);
//   const [municipios, setMunicipios] = useState([]);
//   const [empresas, setEmpresas] = useState([]);
//   const [yacimientos, setYacimientos] = useState([]);

//   const [formData, setFormData] = useState({
//     apellido: '',
//     nombre: '',
//     sexo: '',
//     fechaNacimiento: '',
//     nacionalidad: '',
//     dni: '',
//     cuil: '',
//     estadoCivil: '',
//     direccion: '',
//     provincia: '',
//     departamentoMunicipio: '',
//     codigoPostal: '',
//     telefono: '',
//     email: '',
//     // Campos para Step2
//     nombreFamiliar: '',
//     vinculoFamiliar: '',
//     fechaNacimientoFamiliar: '',
//     sexoFamiliar: '',
//     // Campos para Step3
//     empresa: '',
//     yacimiento: '',
//     areaTrabajo: '',
//     legajo: '',
//     categoriaReciboSueldo: '',
//     fechaIngreso: '',
//     profesion: '',
//     obraSocial: '',
//     correoCorporativo: '',
//     nombreJefeDirecto: '',
//     // Campos para Step4
//     nombreApellido: '',
//     dniBancario: '',
//     banco: '',
//     numeroCuenta: '',
//     cbu: '',
//     sucursal: '',
//     // Firma digital
//     firma: ''
//   });

//   useEffect(() => {
//     fetchProvincias();
//     fetchEmpresas();
//   }, []);

//   useEffect(() => {
//     if (formData.provincia) {
//       const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
//       if (provinciaSeleccionada) {
//         console.log('Selected Provincia ID:', provinciaSeleccionada.id);
//         fetchMunicipios(provinciaSeleccionada.id);
//       }
//     }
//   }, [formData.provincia, provincias]);

//   const fetchProvincias = async () => {
//     try {
//       const response = await fetch('http://asijeminapis.website:2874/api/provincias'); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       setProvincias(data);
//       console.log('Provincias:', data);
//     } catch (error) {
//       console.error('Error fetching provincias:', error);
//       alert('Error fetching provincias: ' + error.message);
//     }
//   };

//   const fetchMunicipios = async (provinciaId) => {
//     try {
//       const response = await fetch(`http://asijeminapis.website:2874/api/municipios`); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       const filteredMunicipios = data.filter(municipio => municipio.provinciaId === provinciaId);
//       setMunicipios(filteredMunicipios);
//       console.log('Municipios:', filteredMunicipios);
//     } catch (error) {
//       console.error('Error fetching municipios:', error);
//       alert('Error fetching municipios: ' + error.message);
//     }
//   };

//   const fetchEmpresas = async () => {
//     try {
//       const response = await fetch('http://asijeminapis.website:2874/api/empresas'); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       setEmpresas(data);
//       console.log('Empresas:', data);
//     } catch (error) {
//       console.error('Error fetching empresas:', error);
//       alert('Error fetching empresas: ' + error.message);
//     }
//   };

//   const fetchYacimientos = async (empresaId) => {
//     try {
//       console.log(`Fetching yacimientos for empresa ID: ${empresaId}`);
//       const response = await fetch(`http://asijeminapis.website:2874/api/yacimientos`); // Cambia a la dirección IP de tu servidor
//       const data = await response.json();
//       const filteredYacimientos = data.filter(yacimiento => yacimiento.empresas.some(e => e.id === empresaId));
//       setYacimientos(filteredYacimientos);
//       console.log('Yacimientos:', filteredYacimientos);
//     } catch (error) {
//       console.error('Error fetching yacimientos:', error);
//       alert('Error fetching yacimientos: ' + error.message);
//     }
//   };

//   const handleNext = () => {
//     setCurrentStep(currentStep + 1);
//   };

//   const handleBack = () => {
//     setCurrentStep(currentStep - 1);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
//     const municipioSeleccionado = municipios.find(mun => mun.nombre === formData.departamentoMunicipio);
//     const empresaSeleccionada = empresas.find(emp => emp.nombre === formData.empresa);
//     const yacimientoSeleccionado = yacimientos.find(yac => yac.nombre === formData.yacimiento);

//     const afiliadoData = {
//       nombre: `${formData.apellido} ${formData.nombre}`,
//       sexo: formData.sexo,
//       fechaNacimiento: formData.fechaNacimiento,
//       dni: formData.dni,
//       cuil: formData.cuil,
//       e_civil: formData.estadoCivil,
//       domicilio: formData.direccion,
//       provincia: provinciaSeleccionada ? provinciaSeleccionada._id : null,
//       localidad: municipioSeleccionado ? municipioSeleccionado._id : null,
//       codigoPostal: formData.codigoPostal,
//       telefono: formData.telefono,
//       email: formData.email,
//       // Datos laborales
//       empresa: empresaSeleccionada ? empresaSeleccionada.idempresa : null,
//       yacimiento: yacimientoSeleccionado ? yacimientoSeleccionado.idyacimiento : null,
//       area_trabajo: formData.areaTrabajo,
//       legajo: formData.legajo,
//       cat_r_sueldo: formData.categoriaReciboSueldo,
//       fecha_ingreso: formData.fechaIngreso,
//       profesion: formData.profesion,
//       obra_social: formData.obraSocial,
//       email_empresa: formData.correoCorporativo,
//       nombre_jefe: formData.nombreJefeDirecto,
//       // Datos bancarios
//       banco: formData.banco,
//       nCuenta: formData.numeroCuenta,
//       cbu: formData.cbu,
//       sucursal: formData.sucursal,
//       // Firma digital
//       firma: formData.firma,
//       // Familiares
//       familiares: formData.nombreFamiliar || formData.vinculoFamiliar || formData.fechaNacimientoFamiliar || formData.sexoFamiliar
//         ? [{
//             nombre: formData.nombreFamiliar,
//             vinculo: formData.vinculoFamiliar || 'Esposa/o',  // Valor por defecto válido
//             fechaNacimiento: formData.fechaNacimientoFamiliar || null,
//             sexo: formData.sexoFamiliar || 'Masculino',  // Valor por defecto válido
//           }]
//         : []
//     };

//     console.log('Submitting afiliado data:', afiliadoData);

//     try {
//       const response = await fetch('http://asijeminapis.website:2874/api/afiliados', { // Cambia a la dirección IP de tu servidor
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(afiliadoData)
//       });
//       const result = await response.json();
//       console.log('Success:', result);
//     } catch (error) {
//       console.error('Error:', error);
//       alert('Error submitting form: ' + error.message);
//     }
//   };

//   return (
//     <div className="multi-step-form">
//       <div className='header-form'>
//         <StepIndicator currentStep={currentStep} totalSteps={5} />
//         <h2>{`Step ${currentStep}: ${currentStep === 1 ? 'Información Personal' : currentStep === 2 ? 'Información del Familiar' : currentStep === 3 ? 'Información Laboral' : currentStep === 4 ? 'Información Bancaria' : 'Firma Digital'}`}</h2>
//       </div>
//       <div className="form-content">
//         {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} provincias={provincias} municipios={municipios} />}
//         {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
//         {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} empresas={empresas} fetchYacimientos={fetchYacimientos} yacimientos={yacimientos} />}
//         {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}
//         {currentStep === 5 && <Step5 formData={formData} setFormData={setFormData} />}
//       </div>
//       <div className="navigation-buttons">
//         {currentStep > 1 && <button onClick={handleBack}>Volver</button>}
//         {currentStep < 5 ? (
//           <button onClick={handleNext}>Siguiente</button>
//         ) : (
//           <button onClick={handleSubmit} disabled={!formData.firma}>Afiliarse</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MultiStepForm;













import React, { useState, useEffect, useCallback } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import StepIndicator from './StepIndicator';
import './MultiStepForm.css';

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [provincias, setProvincias] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [yacimientos, setYacimientos] = useState([]);

  const [formData, setFormData] = useState({
    apellido: '',
    nombre: '',
    sexo: '',
    fechaNacimiento: '',
    nacionalidad: '',
    dni: '',
    cuil: '',
    estadoCivil: '',
    direccion: '',
    provincia: '',
    departamentoMunicipio: '',
    codigoPostal: '',
    telefono: '',
    email: '',
    // Campos para Step2
    nombreFamiliar: '',
    vinculoFamiliar: '',
    fechaNacimientoFamiliar: '',
    sexoFamiliar: '',
    // Campos para Step3
    empresa: '',
    yacimiento: '',
    areaTrabajo: '',
    legajo: '',
    categoriaReciboSueldo: '',
    fechaIngreso: '',
    profesion: '',
    obraSocial: '',
    correoCorporativo: '',
    nombreJefeDirecto: '',
    // Campos para Step4
    nombreApellido: '',
    dniBancario: '',
    banco: '',
    numeroCuenta: '',
    cbu: '',
    sucursal: '',
    // Firma digital
    firma: ''
  });

  useEffect(() => {
    fetchProvincias();
    fetchEmpresas();
  }, []);

  useEffect(() => {
    if (formData.provincia) {
      const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
      if (provinciaSeleccionada) {
        console.log('Selected Provincia ID:', provinciaSeleccionada.id);
        fetchMunicipios(provinciaSeleccionada.id);
      }
    }
  }, [formData.provincia, provincias]);

  const fetchProvincias = async () => {
    try {
      const response = await fetch('https://asijeminapis.website:2874/api/provincias'); // Cambia a la dirección IP de tu servidor
      const data = await response.json();
      setProvincias(data);
      console.log('Provincias:', data);
    } catch (error) {
      console.error('Error fetching provincias:', error);
      alert('Error fetching provincias: ' + error.message);
    }
  };

  const fetchMunicipios = async (provinciaId) => {
    try {
      const response = await fetch(`https://asijeminapis.website:2874/api/municipios`); // Cambia a la dirección IP de tu servidor
      const data = await response.json();
      const filteredMunicipios = data.filter(municipio => municipio.provinciaId === provinciaId);
      setMunicipios(filteredMunicipios);
      console.log('Municipios:', filteredMunicipios);
    } catch (error) {
      console.error('Error fetching municipios:', error);
      alert('Error fetching municipios: ' + error.message);
    }
  };

  const fetchEmpresas = async () => {
    try {
      const response = await fetch('https://asijeminapis.website:2874/api/empresas'); // Cambia a la dirección IP de tu servidor
      const data = await response.json();
      setEmpresas(data);
      console.log('Empresas:', data);
    } catch (error) {
      console.error('Error fetching empresas:', error);
      alert('Error fetching empresas: ' + error.message);
    }
  };

  // const fetchYacimientos = useCallback(async (empresaId) => {
  //   try {
  //     console.log(`Fetching yacimientos for empresa ID: ${empresaId}`);
  //     const response = await fetch(`http://asijeminapis.website:2874/api/yacimientos`);
  //     const data = await response.json();
  
  //     const filteredYacimientos = data.filter(yacimiento => 
  //       yacimiento.idempresa && yacimiento.idempresa.some(emp => emp.$oid === empresaId)
  //     );
  
  //     setYacimientos(filteredYacimientos);
  //     console.log('Filtered Yacimientos:', filteredYacimientos);
  //   } catch (error) {
  //     console.error('Error fetching yacimientos:', error);
  //     alert('Error fetching yacimientos: ' + error.message);
  //   }
  // }, []);
  
  const fetchYacimientos = useCallback(async (empresaId) => {
    try {
      console.log(`Fetching yacimientos for empresa ID: ${empresaId}`);
      const response = await fetch(`https://asijeminapis.website:2874/api/yacimientos`);
      const data = await response.json();
      console.log('Yacimientos data fetched:', data);
  
      const filteredYacimientos = data.filter(yacimiento => 
        yacimiento.idempresa && yacimiento.idempresa.some(emp => {
          console.log('Checking idempresa:', emp);
          return emp.$oid === empresaId || emp === empresaId;
        })
      );
  
      setYacimientos(filteredYacimientos);
      console.log('Filtered Yacimientos:', filteredYacimientos);
    } catch (error) {
      console.error('Error fetching yacimientos:', error);
      alert('Error fetching yacimientos: ' + error.message);
    }
  }, []);
  
  
    
    

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const provinciaSeleccionada = provincias.find(prov => prov.nombre === formData.provincia);
    const municipioSeleccionado = municipios.find(mun => mun.nombre === formData.departamentoMunicipio);
    const empresaSeleccionada = empresas.find(emp => emp.nombre === formData.empresa);
    const yacimientoSeleccionado = yacimientos.find(yac => yac.nombre === formData.yacimiento);

    const afiliadoData = {
      nombre: `${formData.apellido} ${formData.nombre}`,
      sexo: formData.sexo,
      fechaNacimiento: formData.fechaNacimiento,
      dni: formData.dni,
      cuil: formData.cuil,
      e_civil: formData.estadoCivil,
      domicilio: formData.direccion,
      provincia: provinciaSeleccionada ? provinciaSeleccionada._id : null,
      localidad: municipioSeleccionado ? municipioSeleccionado._id : null,
      codigoPostal: formData.codigoPostal,
      telefono: formData.telefono,
      email: formData.email,
      // Datos laborales
      empresa: empresaSeleccionada ? empresaSeleccionada.idempresa : null,
      yacimiento: yacimientoSeleccionado ? yacimientoSeleccionado.idyacimiento : null,
      area_trabajo: formData.areaTrabajo,
      legajo: formData.legajo,
      cat_r_sueldo: formData.categoriaReciboSueldo,
      fecha_ingreso: formData.fechaIngreso,
      profesion: formData.profesion,
      obra_social: formData.obraSocial,
      email_empresa: formData.correoCorporativo,
      nombre_jefe: formData.nombreJefeDirecto,
      // Datos bancarios
      banco: formData.banco,
      nCuenta: formData.numeroCuenta,
      cbu: formData.cbu,
      sucursal: formData.sucursal,
      // Firma digital
      firma: formData.firma,
      // Familiares
      familiares: formData.nombreFamiliar || formData.vinculoFamiliar || formData.fechaNacimientoFamiliar || formData.sexoFamiliar
        ? [{
            nombre: formData.nombreFamiliar,
            vinculo: formData.vinculoFamiliar || 'Esposa/o',  // Valor por defecto válido
            fechaNacimiento: formData.fechaNacimientoFamiliar || null,
            sexo: formData.sexoFamiliar || 'Masculino',  // Valor por defecto válido
          }]
        : []
    };

    console.log('Submitting afiliado data:', afiliadoData);

    try {
      const response = await fetch('https://asijeminapis.website:2874/api/afiliados', { // Cambia a la dirección IP de tu servidor
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(afiliadoData)
      });
      const result = await response.json();
      console.log('Success:', result);
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form: ' + error.message);
    }
  };

  return (
    <div className="multi-step-form">
      <div className='header-form'>
        <StepIndicator currentStep={currentStep} totalSteps={5} />
        <h2>{`Step ${currentStep}: ${currentStep === 1 ? 'Información Personal' : currentStep === 2 ? 'Información del Familiar' : currentStep === 3 ? 'Información Laboral' : currentStep === 4 ? 'Información Bancaria' : 'Firma Digital'}`}</h2>
      </div>
      <div className="form-content">
        {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} provincias={provincias} municipios={municipios} />}
        {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
        {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} empresas={empresas} fetchYacimientos={fetchYacimientos} yacimientos={yacimientos} />}
        {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}
        {currentStep === 5 && <Step5 formData={formData} setFormData={setFormData} />}
      </div>
      <div className="navigation-buttons">
        {currentStep > 1 && <button onClick={handleBack}>Volver</button>}
        {currentStep < 5 ? (
          <button onClick={handleNext}>Siguiente</button>
        ) : (
          <button onClick={handleSubmit} disabled={!formData.firma}>Afiliarse</button>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
