import React from 'react'

const Step4 = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  return (
    <div>
      <form>
        <label>
          Nombre y Apellido:
          <input type="text" name="nombreApellido" value={formData.nombreApellido} onChange={handleChange} />
        </label>
        <br />
        <label>
          DNI:
          <input type="number" name="dniBancario" value={formData.dniBancario} onChange={handleChange} />
        </label>
        <br />
        <label>
          Banco:
          <input type="text" name="banco" value={formData.banco} onChange={handleChange} />
        </label>
        <br />
        <label>
          Número de Cuenta:
          <input type="text" name="numeroCuenta" value={formData.numeroCuenta} onChange={handleChange} />
        </label>
        <br />
        <label>
          CBU:
          <input type="text" name="cbu" value={formData.cbu} onChange={handleChange} />
        </label>
        <br />
        <label>
          Sucursal:
          <input type="text" name="sucursal" value={formData.sucursal} onChange={handleChange} />
        </label>
      </form>
    </div>
  )
}

export default Step4
