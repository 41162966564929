import React from 'react'

const Step2 = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  return (
    <div>
      <form>
        <label>
          Nombre y Apellido:
          <input type="text" name="nombreFamiliar" value={formData.nombreFamiliar} onChange={handleChange} />
        </label>
        <br />
        <label>
          Vínculo:
          <select name="vinculoFamiliar" value={formData.vinculoFamiliar} onChange={handleChange}>
            <option value="">Selecciona...</option>
            <option value="Esposa/o">Esposa/o</option>
            <option value="Concubina/o">Concubina/o</option>
            <option value="Hijo/a">Hijo/a</option>
            <option value="Padre">Padre</option>
            <option value="Madre">Madre</option>
            <option value="Hermano">Hermano</option>
          </select>
        </label>
        <br />
        <label>
          Fecha de Nacimiento:
          <input type="date" name="fechaNacimientoFamiliar" value={formData.fechaNacimientoFamiliar} onChange={handleChange} />
        </label>
        <br />
        <label>
          Sexo:
          <select name="sexoFamiliar" value={formData.sexoFamiliar} onChange={handleChange}>
            <option value="">Selecciona...</option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </select>
        </label>
      </form>
    </div>
  )
}

export default Step2
