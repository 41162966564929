import React, { useRef, useState, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import imageCompression from 'browser-image-compression'
import './SignaturePad.css'

const SignaturePad = ({ setFormData, formData }) => {
  const [isSigned, setIsSigned] = useState(false)
  const sigCanvas = useRef({})

  const handleClear = () => {
    sigCanvas.current.clear()
    setIsSigned(false)
  }

  const handleSave = async () => {
    if (sigCanvas.current.isEmpty()) {
      alert("Por favor, realice la firma antes de guardar.")
      return
    }

    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/jpeg')
    const compressedSignature = await compressImage(signature)

    setFormData({ ...formData, firma: compressedSignature })
    setIsSigned(true)
  }

  const compressImage = async (dataUrl) => {
    const file = dataURLtoFile(dataUrl, 'firma.jpg')
    const options = {
      maxSizeMB: 0.1, // 100 KB
      maxWidthOrHeight: 500,
      useWebWorker: true
    }
    const compressedFile = await imageCompression(file, options)
    return await imageCompression.getDataUrlFromFile(compressedFile)
  }

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  useEffect(() => {
    const resizeCanvas = () => {
      const canvas = sigCanvas.current.getCanvas()
      canvas.width = window.innerWidth * 0.8
      canvas.height = window.innerHeight * 0.7
    }

    window.addEventListener('resize', resizeCanvas)
    resizeCanvas()

    return () => window.removeEventListener('resize', resizeCanvas)
  }, [])

  return (
    <div className="signature-container">
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ className: 'sigCanvas' }}
      />
      <div className="signature-buttons">
        <button onClick={handleClear}>Borrar</button>
        <button onClick={handleSave}>Guardar Firma</button>
      </div>
      {isSigned && <p>Firma guardada correctamente.</p>}
    </div>
  )
}

export default SignaturePad
