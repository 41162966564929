import React from 'react'

const Formulario = () => {
  return (
    <div>
        <h2>Formulario de Afiliacion</h2>
    </div>
  )
}

export default Formulario
