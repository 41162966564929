import React, { useEffect } from 'react';

const Step3 = ({ formData, setFormData, empresas, fetchYacimientos, yacimientos }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (formData.empresa) {
      const empresaSeleccionada = empresas.find(emp => emp.nombre === formData.empresa);
      if (empresaSeleccionada) {
        console.log('Empresa seleccionada:', empresaSeleccionada);
        fetchYacimientos(empresaSeleccionada._id.$oid || empresaSeleccionada._id);
      }
    }
  }, [formData.empresa, empresas, fetchYacimientos]);

  return (
    <div>
      <h2>Step 3: Información Laboral</h2>
      <form>
        <label>
          Empresa:
          <select name="empresa" value={formData.empresa} onChange={handleChange}>
            <option value="">Selecciona...</option>
            {empresas.map(empresa => (
              <option key={empresa._id.$oid || empresa._id} value={empresa.nombre}>{empresa.nombre}</option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Yacimiento:
          <select name="yacimiento" value={formData.yacimiento} onChange={handleChange}>
            <option value="">Selecciona...</option>
            {yacimientos.map(yacimiento => (
              <option key={yacimiento._id.$oid || yacimiento._id} value={yacimiento.nombre}>{yacimiento.nombre}</option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Área de Trabajo:
          <input type="text" name="areaTrabajo" value={formData.areaTrabajo} onChange={handleChange} />
        </label>
        <br />
        <label>
          Legajo:
          <input type="text" name="legajo" value={formData.legajo} onChange={handleChange} />
        </label>
        <br />
        <label>
          Categoría Recibo de Sueldo:
          <input type="text" name="categoriaReciboSueldo" value={formData.categoriaReciboSueldo} onChange={handleChange} />
        </label>
        <br />
        <label>
          Fecha de Ingreso:
          <input type="date" name="fechaIngreso" value={formData.fechaIngreso} onChange={handleChange} />
        </label>
        <br />
        <label>
          Profesión:
          <input type="text" name="profesion" value={formData.profesion} onChange={handleChange} />
        </label>
        <br />
        <label>
          Obra Social:
          <input type="text" name="obraSocial" value={formData.obraSocial} onChange={handleChange} />
        </label>
        <br />
        <label>
          Correo Corporativo:
          <input type="email" name="correoCorporativo" value={formData.correoCorporativo} onChange={handleChange} />
        </label>
        <br />
        <label>
          Nombre Jefe Directo:
          <input type="text" name="nombreJefeDirecto" value={formData.nombreJefeDirecto} onChange={handleChange} />
        </label>
      </form>
    </div>
  );
};

export default Step3;
