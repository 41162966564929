import React from 'react'
import './StepIndicator.css'

const StepIndicator = ({ currentStep, totalSteps }) => {
  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1)

  return (
    <div className="step-indicator">
      {steps.map(step => (
        <div key={step} className={`step ${step <= currentStep ? 'active' : ''}`}>
          <span className="step-number">{step} </span>
        </div>
      ))}
    </div>
  )
}

export default StepIndicator
